import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

const ButtonGroup = ({
  tight,
  isCentered,
  alignRight,
  alignLeft,
  reverse,
  children,
  className
}) => {
  const buttonGroupClass = classNames(className, {
    "button-group": true,
    tight: tight,
    "is-centered": isCentered,
    "align-right": alignRight,
    "align-left": alignLeft,
    "reverse-buttons-mobile": reverse
  })
  return (
    <div
      className={
        buttonGroupClass + ` has-${React.Children.count(children)}-button`
      }>
      {children}
    </div>
  )
}

ButtonGroup.propTypes = {
  isCentered: PropTypes.bool,
  isCenteredMobile: PropTypes.bool,
  tight: PropTypes.bool,
  alignLeft: PropTypes.bool,
  alignRight: PropTypes.bool,
  children: PropTypes.node,
  reverse: PropTypes.bool
}

export default ButtonGroup
