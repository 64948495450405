import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowLeft } from "@fortawesome/pro-light-svg-icons/faLongArrowLeft"
import { faFileDownload } from "@fortawesome/pro-solid-svg-icons/faFileDownload"

const Button = ({
  goBack,
  className,
  standalone,
  white,
  whiteMobile,
  border,
  minimal,
  contained,
  tel,
  href,
  buttonText,
  download,
  external,
  destination,
  appearance,
  anchor
}) => {
  const handleGoBack = () => {
    window.history.back()
  }

  const btnClass = classNames("standard-button", className, {
    standalone: standalone,
    "go-back-button-top-left": goBack,
    white: white || appearance === "white",
    border: border ? border : false,
    minimal: minimal || appearance === "minimal",
    contained: contained || appearance === "contained"
  })

  const backBtnClass = classNames("go-back-button", className, {
    "go-back-button": true,
    "go-back-button-top-left": goBack,
    white: white ? white : false,
    "white-mobile": whiteMobile ? whiteMobile : false,
    minimal: minimal ? minimal : false
  })

  // for telephone links
  if (tel || destination === "tel") {
    return (
      <a className={btnClass} href={href}>
        {buttonText}
      </a>
    )
  }
  // for external links
  if (external || destination === "external") {
    return (
      <a
        className={btnClass}
        href={href}
        target="_blank"
        rel="noopener noreferrer">
        {buttonText}
      </a>
    )
  }
  if (anchor || destination === "anchor") {
    let href = href
    if (href.charAt(href.length - 1) === "/")
      href = href.substring(0, href.length - 1)
    return (
      <AnchorLink className={btnClass} to={href}>
        {buttonText}
      </AnchorLink>
    )
  }
  // for downloads
  if (download || destination === "download") {
    return (
      <a className={btnClass} href={href} target="_blank" download>
        <FontAwesomeIcon className="button-icon" icon={faFileDownload} />
        {buttonText}
      </a>
    )
  }
  // Insert long left arrow for Back "Button"
  else if (goBack && !href) {
    return (
      <a className={backBtnClass} onClick={handleGoBack}>
        <FontAwesomeIcon className="button-icon" icon={faLongArrowLeft} />
        {buttonText}
      </a>
    )
  } else if (goBack) {
    return (
      <Link className={backBtnClass} to={href}>
        <FontAwesomeIcon className="button-icon" icon={faLongArrowLeft} />
        {buttonText}
      </Link>
    )
  }
  // Classic Gatsby Link styled as a button; assumed to not be external
  else {
    let btnHref = href
    if (href.charAt(0) !== "/") btnHref = "/" + href
    if (href.charAt(href.length - 1) !== "/") btnHref = href + "/"
    return (
      <Link className={btnClass} to={btnHref}>
        {buttonText}
      </Link>
    )
  }
}

Button.propTypes = {
  standalone: PropTypes.bool,
  anchor: PropTypes.bool,
  minimal: PropTypes.bool,
  white: PropTypes.bool,
  border: PropTypes.bool,
  contained: PropTypes.bool,
  goBack: PropTypes.bool,
  download: PropTypes.bool,
  textAlign: PropTypes.string,
  href: PropTypes.string,
  buttonText: PropTypes.string,
  className: PropTypes.string
}

export default Button
